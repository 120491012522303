import { request } from './request'

// 获取筛选条件（类目）
export function getAuctionNewsMenuList() {
  return request({
    url: '/txapi/message/getAuctionNewsMenuList',
    method: 'get',
  })
}

// 获取筛选条件（地区）
export function getAuctionNewsMenuListProvince(data) {
  return request({
    url: '/txapi/message/getAuctionNewsMenuListProvince',
    method: 'get',
    params: data
  })
}

// 获取招标列表
export function getAuctionNews(data) {
  return request({
    url: '/txapi/message/getAuctionNews',
    method: 'get',
    params: data
  })
}


// 获取招标列表详情
export function getAuctionNewsCheck(data) {
  return request({
    url: '/txapi/message/getAuctionNewsCheck',
    method: 'get',
    params: data
  })
}

// 获取招标列表详情（上一页，下一页）
export function getPriceNewsPageNext(data) {
  return request({
    url: '/txapi/message/getPriceNewsPageNext',
    method: 'get',
    params: data
  })
}
export function getPriceNewsPageNextzb(data) {
  return request({
    url: '/txapi/message/getNextAuctionNews',
    method: 'get',
    params: data
  })
}
// 获取是否已购买产品
export function getAuctionNewsGps() {
  return request({
    url: '/txapi/message/getAuctionNewsGps',
    method: 'get',
  })
}

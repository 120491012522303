<template>
  <div>
    <img-nav />
    <filter-screen v-if="isReady" @filterClk="filterClk" />
    <div class="content w1400">
      <div class="left">
        <news-list
          v-if="isReady"
          :list="newsList"
          :total="total"
          :page="page"
          :loading="loading"
          @onPage="onPage"
        />
      </div>
      <div class="mrTop30">
        <q-rcode />
        <scroll-box
          v-if="isReady"
          :option="transOpt"
          @onClick="rollClk"
          class="mrTop30"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import FilterScreen from './compontents/layout/FilterScreen'
import NewsList from './compontents/layout/NewsList'

import {
  getAuctionNewsMenuList,
  getAuctionNewsMenuListProvince,
  getAuctionNews,
  getAuctionNewsGps,
} from 'network/bidAuction'

export default {
  name: 'BidAuction',
  data() {
    return {
      setVip:"",
      isReady: false,
      newsList: [],
      total: 0,
      page: 1,
      loading: false,
      transOpt: {
        title: '热门发布',
        list: [],
        isHover: true,
        context: (str) => {
          if (str.title.length >= 10) {
            str.title = str.title.substr(0, 10) + '...'
          }
          return `[${str.name}] ${str.title}`
        },
      },
    }
  },
  created() {
    this.setVip = localStorage.getItem('setVip')
    this.apiRes()
  },
  methods: {
    ...mapMutations('bidAuction', [
      'setCate',
      'setCity',
      'setCateList',
      'setCityList',
    ]),
    apiRes() {
      let apis = [
        this.getAuctionNewsMenuList(),
        this.getAuctionNewsMenuListProvince(),
        this.getAuctionNews(),
        this.getAuctionNews(1),
      ]
      Promise.all(apis).then((res) => (this.isReady = true))
    },
    filterClk(e) {
      if (e) {
        this.getAuctionNewsMenuListProvince()
      }
      this.getAuctionNews()
    },
    onPage(e) {
      this.page = e
      this.getAuctionNews()
    },
    rollClk(e) {
      // getAuctionNewsGps().then((res) => {
      //   if (res.code == 0) {
          if (this.setVip==1) {
            this.$router.push({
              name: 'BidAuctionDel',
              query: {
                id: e.id,
                page: e.page,
                subjectId: e.subjectId,
                marketId: e.marketId,
              },
            })
          } else {
            this.$Modal.warning({
              title: '提示',
              content:
                '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
            })
          }
      //   }
      // })
    },
    /* api相关 */
    getAuctionNewsMenuList() {
      return new Promise((resolve) => {
        getAuctionNewsMenuList().then((res) => {
          if (res.code == 0) {
            this.setCateList(res.data)
            resolve()
          }
        })
      })
    },
    getAuctionNewsMenuListProvince() {
      return new Promise((resolve) => {
        getAuctionNewsMenuListProvince({ id: this.filters.cate }).then(
          (res) => {
            if (res.code == 0) {
              let list = res.data.map((e, i) => {
                return {
                  id: i + 1,
                  name: e.province,
                }
              })
              this.setCityList(list)
              resolve()
            }
          }
        )
      })
    },
    getAuctionNews(num) {
      return new Promise((resolve) => {
        let p = this.filters.city
        let o = this.filters.cityList.find((e) => e.id == p)
        let data = {
          productId: 16230,
          marketId: 1276,
          type: this.filters.cate,
          province: o ? o.name : null,
          num: this.page,
          size: 7,
        }
        if (num == 1) {
          data.size = 20
        }
        this.loading = true
        getAuctionNews(data).then((res) => {
          this.loading = false
          if (res.code == 0) {
            if (num == 1) {
              this.transOpt.list = res.data
            } else {
              this.newsList = res.data
              this.total = res.count
            }
            resolve()
          }
        })
      })
    },
  },
  components: {
    FilterScreen,
    NewsList,
  },
  computed: {
    ...mapState('bidAuction', ['filters']),
  },
}
</script>

<style scoped>
.mrTop30 {
  margin-top: 30px;
}

.content {
  display: flex;
  justify-content: space-between;
}
</style>

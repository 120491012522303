<template>
  <div class="all w1400 mrTop50">
    <filter-item
      title="类目"
      @onClick="cateClk"
      :id="filters.cate"
      :list="filters.cateList"
      :hasAll="false"
    />
    <filter-item
      title="地区"
      @onClick="cityClk"
      :id="filters.city"
      :list="filters.cityList"
      class="mrTop10"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'FilterScreen',
  data() {
    return {}
  },
  methods: {
    ...mapMutations('bidAuction', ['setCate', 'setCity']),
    cateClk(e) {
      if (e.id != this.filters.cate) {
        this.setCity(0)
        this.setCate(e.id)
      }
      this.$emit('filterClk', true)
    },
    cityClk(e) {
      this.setCity(e.id)
      this.$emit('filterClk', false)
    },
  },
  computed: {
    ...mapState('bidAuction', ['filters']),
  },
}
</script>

<style scoped>
.mrTop50 {
  margin-top: 50px;
}
.mrTop10 {
  margin-top: 10px;
}
.all {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e6ebf5;
}
</style>

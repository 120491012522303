<template>
  <div @click="itemClk" class="itemAll">
    <div class="left">
      <h1>{{ ftext(data.title, 30) }}</h1>
      <p>{{ ftext(data.content, 100) }}</p>
      <span>发布时间：{{ fdate }}</span>
    </div>
    <div class="right">
      <p>所属类型：{{ data.name }}</p>
      <p>所在地区：{{ fcity }}</p>
      <p>
        有效时间：<i>{{ fedate }}</i>
      </p>
    </div>
  </div>
</template>

<script>
import { getAuctionNewsGps } from 'network/bidAuction'

export default {
  name: 'NewsListItem',
  props: {
    data: Object,
  },
  data() {
    return {
      setVip:""
    }
  },
  created() {
    this.setVip = localStorage.getItem('setVip')
  },
  methods: {
    itemClk() {
      // getAuctionNewsGps().then((res) => {
      //   if (res.code == 0) {
          if (this.setVip==1) {
            this.$router.push({
              name: 'BidAuctionDel',
              query: {
                id: this.data.id,
                page: this.data.page,
                subjectId: this.data.subjectId,
                marketId: this.data.marketId,
              },
            })
          } else {
            this.$Modal.warning({
              title: '提示',
              content:
                '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
            })
          }
      //   }
      // })
    },
  },
  computed: {
    ftext() {
      return function (text, len) {
        let rText = text.replace(/<[^>]+>/g, '')
        if (rText.length < len) return rText
        return rText.substr(0, len) + '...'
      }
    },
    fdate() {
      return this.$handle.dateFormat(this.data.create, 'y/m/d')
    },
    fedate() {
      return this.$handle.dateFormat(this.data.effectiveDate, 'y/m/d')
    },
    fcity() {
      return this.data.province + this.data.city
    },
  },
}
</script>

<style scoped>
.itemAll {
  position: relative;
  width: 800px;
  height: 180px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px dashed #e6ebf5;
  cursor: pointer;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 100%;
}

.left h1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}

.left p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  line-height: 30px;
}

.left span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 30px;
}

.right {
  position: absolute;
  right: 0;
  bottom: 30px;
  min-width: 150px;
}

.right p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.right p i {
  font-style: normal;
  color: #e83033;
}
</style>
